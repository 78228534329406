import {
  ElementType,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { isDefined } from 'utils/functional';

import {
  Button,
  DefaultButton,
  TooltipBox,
  TooltipContainer,
} from './LegacyTooltip.styled';

type Props = {
  'data-modal-focus'?: 'true' | 'false';
  green?: boolean;
  button?: ReactNode;
  className?: string;
  children: ReactNode;
  component?: ElementType;
  disabled?: boolean;
  greyColor?: boolean;
  lightColor?: boolean;
  onClick?: MouseEventHandler;
  tabIndex?: number;
  width?: number;
  offset?: number;
  initialPositionRight?: number;
  initialButtonWidth?: number;
  qaId?: string;
};

export function LegacyTooltip({
  button,
  className,
  children,
  component,
  disabled,
  green,
  greyColor,
  lightColor,
  onClick,
  tabIndex,
  initialButtonWidth,
  initialPositionRight,
  width = 300,
  offset = 10,
  qaId,
  ...props
}: Props) {
  const [positionRight, setPositionRight] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(initialButtonWidth || 0);
  const [isMounted, setIsMounted] = useState(false);

  const buttonRef = useRef(null);

  const resizeHandler = useCallback(() => {
    if (buttonRef.current) {
      if (isDefined(initialPositionRight) && !isMounted) {
        return setPositionRight(initialPositionRight);
      }

      // @ts-expect-error TS(2339): Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
      const rect = buttonRef.current.getBoundingClientRect();
      const overflowWidth = width - rect.width / 2;

      setButtonWidth(rect.width);

      if (rect.left - overflowWidth < 10) {
        setPositionRight(rect.left - overflowWidth - 10);
      } else {
        setPositionRight(0);
      }
      setIsMounted(true);
    }
    return undefined;
  }, [initialPositionRight, isMounted, width]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  useEffect(() => {
    resizeHandler();
  }, [resizeHandler]);

  return (
    <Button
      data-qa-id={qaId}
      className={className}
      ref={buttonRef}
      type={!component || component === 'button' ? 'button' : undefined}
      component={component || 'button'}
      aria-label={getText('Show tooltip')}
      onClick={onClick}
      data-modal-focus={props['data-modal-focus']}
      disabled={disabled}
      tabIndex={tabIndex || '0'}
      // @ts-expect-error TS(2769): No overload matches this call.
      $lightColor={lightColor}
    >
      <TooltipContainer
        $buttonWidth={buttonWidth}
        $offset={offset}
        $width={width}
        // @ts-expect-error TS(2769): No overload matches this call.
        $green={green}
        $right={positionRight}
      >
        <TooltipBox
          // @ts-expect-error TS(2769): No overload matches this call.
          $green={green}
          $width={width}
          $right={positionRight}
        >
          {children}
        </TooltipBox>
      </TooltipContainer>
      <div>
        {button || (
          <DefaultButton $lightColor={lightColor} $greyColor={greyColor} />
        )}
      </div>
    </Button>
  );
}
