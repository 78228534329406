export const US_STATES_ONLY = [
  {
    id: 'AL',
    name: getText('Alabama'),
  },
  {
    id: 'AK',
    name: getText('Alaska'),
  },
  {
    id: 'AZ',
    name: getText('Arizona'),
  },
  {
    id: 'AR',
    name: getText('Arkansas'),
  },
  {
    id: 'CA',
    name: getText('California'),
  },
  {
    id: 'CO',
    name: getText('Colorado'),
  },
  {
    id: 'CT',
    name: getText('Connecticut'),
  },
  {
    id: 'DE',
    name: getText('Delaware'),
  },
  {
    id: 'DC',
    name: getText('District of Columbia'),
  },
  {
    id: 'FL',
    name: getText('Florida'),
  },
  {
    id: 'GA',
    name: getText('Georgia'),
  },
  {
    id: 'HI',
    name: getText('Hawaii'),
  },
  {
    id: 'ID',
    name: getText('Idaho'),
  },
  {
    id: 'IL',
    name: getText('Illinois'),
  },
  {
    id: 'IN',
    name: getText('Indiana'),
  },
  {
    id: 'IA',
    name: getText('Iowa'),
  },
  {
    id: 'KS',
    name: getText('Kansas'),
  },
  {
    id: 'KY',
    name: getText('Kentucky'),
  },
  {
    id: 'LA',
    name: getText('Louisiana'),
  },
  {
    id: 'ME',
    name: getText('Maine'),
  },
  {
    id: 'MD',
    name: getText('Maryland'),
  },
  {
    id: 'MA',
    name: getText('Massachusetts'),
  },
  {
    id: 'MI',
    name: getText('Michigan'),
  },
  {
    id: 'MN',
    name: getText('Minnesota'),
  },
  {
    id: 'MS',
    name: getText('Mississippi'),
  },
  {
    id: 'MO',
    name: getText('Missouri'),
  },
  {
    id: 'MT',
    name: getText('Montana'),
  },
  {
    id: 'NE',
    name: getText('Nebraska'),
  },
  {
    id: 'NV',
    name: getText('Nevada'),
  },
  {
    id: 'NH',
    name: getText('New Hampshire'),
  },
  {
    id: 'NJ',
    name: getText('New Jersey'),
  },
  {
    id: 'NM',
    name: getText('New Mexico'),
  },
  {
    id: 'NY',
    name: getText('New York'),
  },
  {
    id: 'NC',
    name: getText('North Carolina'),
  },
  {
    id: 'ND',
    name: getText('North Dakota'),
  },
  {
    id: 'OH',
    name: getText('Ohio'),
  },
  {
    id: 'OK',
    name: getText('Oklahoma'),
  },
  {
    id: 'OR',
    name: getText('Oregon'),
  },
  {
    id: 'PA',
    name: getText('Pennsylvania'),
  },
  {
    id: 'RI',
    name: getText('Rhode Island'),
  },
  {
    id: 'SC',
    name: getText('South Carolina'),
  },
  {
    id: 'SD',
    name: getText('South Dakota'),
  },
  {
    id: 'TN',
    name: getText('Tennessee'),
  },
  {
    id: 'TX',
    name: getText('Texas'),
  },
  {
    id: 'UT',
    name: getText('Utah'),
  },
  {
    id: 'VT',
    name: getText('Vermont'),
  },
  {
    id: 'VA',
    name: getText('Virginia'),
  },
  {
    id: 'WA',
    name: getText('Washington'),
  },
  {
    id: 'WV',
    name: getText('West Virginia'),
  },
  {
    id: 'WI',
    name: getText('Wisconsin'),
  },
  {
    id: 'WY',
    name: getText('Wyoming'),
  },
];

export const US_STATES_TERRITORIES = [
  {
    id: 'AS',
    name: getText('American Samoa'),
  },
  {
    id: 'FM',
    name: getText('Federated States of Micronesia'),
  },
  {
    id: 'GU',
    name: getText('Guam'),
  },
  {
    id: 'MH',
    name: getText('Marshall Islands'),
  },
  {
    id: 'MP',
    name: getText('Northern Mariana Islands'),
  },
  {
    id: 'PW',
    name: getText('Palau'),
  },
  {
    id: 'PR',
    name: getText('Puerto Rico'),
  },
  {
    id: 'VI',
    name: getText('Virgin Islands'),
  },
];

export const US_STATES = [...US_STATES_ONLY, ...US_STATES_TERRITORIES];
