export const SALARY_PERIODS = [
  {
    id: 'YEAR',

    /* TRANSLATORS: job view payment time period */
    unit: getText('year'),

    /* TRANSLATORS: job form payment time period */
    name: getText('Year'),
  },
  {
    id: 'HOUR',

    /* TRANSLATORS: job view payment time period */
    unit: getText('hour'),

    /* TRANSLATORS: job form payment time period */
    name: getText('Hour'),
  },
  {
    id: 'DAY',

    /* TRANSLATORS: job view payment time period */
    unit: getText('day'),

    /* TRANSLATORS: job form payment time period */
    name: getText('Day'),
  },
  {
    id: 'WEEK',

    /* TRANSLATORS: job view payment time period */
    unit: getText('week'),

    /* TRANSLATORS: job form payment time period */
    name: getText('Week'),
  },
  {
    id: 'MONTH',

    /* TRANSLATORS: job view payment time period */
    unit: getText('month'),

    /* TRANSLATORS: job form payment time period */
    name: getText('Month'),
  },
] as const;

export type SalaryPeriodId = (typeof SALARY_PERIODS)[number]['id'];

export const getSalaryPeriodHumanName = (salaryPeriod: string) => {
  const SALARY_PERIOD = SALARY_PERIODS.find((jp) => jp.id === salaryPeriod);

  if (SALARY_PERIOD) {
    return SALARY_PERIOD.unit;
  }
};
