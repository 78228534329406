import { sort } from 'utils/functional';

const UNSORTED_COUNTRIES = [
  {
    id: 'AF',
    name: getText('Afghanistan'),
  },
  {
    id: 'AX',
    name: getText('Åland'),
  },
  {
    id: 'AL',
    name: getText('Albania'),
  },
  {
    id: 'DZ',
    name: getText('Algeria'),
  },
  {
    id: 'AS',
    name: getText('American Samoa'),
  },
  {
    id: 'AD',
    name: getText('Andorra'),
  },
  {
    id: 'AO',
    name: getText('Angola'),
  },
  {
    id: 'AI',
    name: getText('Anguilla'),
  },
  {
    id: 'AQ',
    name: getText('Antarctica'),
  },
  {
    id: 'AG',
    name: getText('Antigua and Barbuda'),
  },
  {
    id: 'AR',
    name: getText('Argentina'),
  },
  {
    id: 'AM',
    name: getText('Armenia'),
  },
  {
    id: 'AW',
    name: getText('Aruba'),
  },
  {
    id: 'AU',
    name: getText('Australia'),
  },
  {
    id: 'AT',
    name: getText('Austria'),
  },
  {
    id: 'AZ',
    name: getText('Azerbaijan'),
  },
  {
    id: 'BS',
    name: getText('Bahamas'),
  },
  {
    id: 'BH',
    name: getText('Bahrain'),
  },
  {
    id: 'BD',
    name: getText('Bangladesh'),
  },
  {
    id: 'BB',
    name: getText('Barbados'),
  },
  {
    id: 'BY',
    name: getText('Belarus'),
  },
  {
    id: 'BE',
    name: getText('Belgium'),
  },
  {
    id: 'BZ',
    name: getText('Belize'),
  },
  {
    id: 'BJ',
    name: getText('Benin'),
  },
  {
    id: 'BM',
    name: getText('Bermuda'),
  },
  {
    id: 'BT',
    name: getText('Bhutan'),
  },
  {
    id: 'BO',
    name: getText('Bolivia'),
  },
  {
    id: 'BQ',
    name: getText('Bonaire'),
  },
  {
    id: 'BA',
    name: getText('Bosnia and Herzegovina'),
  },
  {
    id: 'BW',
    name: getText('Botswana'),
  },
  {
    id: 'BV',
    name: getText('Bouvet Island'),
  },
  {
    id: 'BR',
    name: getText('Brazil'),
  },
  {
    id: 'IO',
    name: getText('British Indian Ocean Territory'),
  },
  {
    id: 'VG',
    name: getText('British Virgin Islands'),
  },
  {
    id: 'BN',
    name: getText('Brunei'),
  },
  {
    id: 'BG',
    name: getText('Bulgaria'),
  },
  {
    id: 'BF',
    name: getText('Burkina Faso'),
  },
  {
    id: 'BI',
    name: getText('Burundi'),
  },
  {
    id: 'KH',
    name: getText('Cambodia'),
  },
  {
    id: 'CM',
    name: getText('Cameroon'),
  },
  {
    id: 'CA',
    name: getText('Canada'),
  },
  {
    id: 'CV',
    name: getText('Cape Verde'),
  },
  {
    id: 'KY',
    name: getText('Cayman Islands'),
  },
  {
    id: 'CF',
    name: getText('Central African Republic'),
  },
  {
    id: 'TD',
    name: getText('Chad'),
  },
  {
    id: 'CL',
    name: getText('Chile'),
  },
  {
    id: 'CN',
    name: getText('China'),
  },
  {
    id: 'CX',
    name: getText('Christmas Island'),
  },
  {
    id: 'CC',
    name: getText('Cocos [Keeling] Islands'),
  },
  {
    id: 'CO',
    name: getText('Colombia'),
  },
  {
    id: 'KM',
    name: getText('Comoros'),
  },
  {
    id: 'CK',
    name: getText('Cook Islands'),
  },
  {
    id: 'CR',
    name: getText('Costa Rica'),
  },
  {
    id: 'HR',
    name: getText('Croatia'),
  },
  {
    id: 'CU',
    name: getText('Cuba'),
  },
  {
    id: 'CW',
    name: getText('Curacao'),
  },
  {
    id: 'CY',
    name: getText('Cyprus'),
  },
  {
    id: 'CZ',
    name: getText('Czechia'),
  },
  {
    id: 'CD',
    name: getText('Democratic Republic of the Congo'),
  },
  {
    id: 'DK',
    name: getText('Denmark'),
  },
  {
    id: 'DJ',
    name: getText('Djibouti'),
  },
  {
    id: 'DM',
    name: getText('Dominica'),
  },
  {
    id: 'DO',
    name: getText('Dominican Republic'),
  },
  {
    id: 'TL',
    name: getText('East Timor'),
  },
  {
    id: 'EC',
    name: getText('Ecuador'),
  },
  {
    id: 'EG',
    name: getText('Egypt'),
  },
  {
    id: 'SV',
    name: getText('El Salvador'),
  },
  {
    id: 'GQ',
    name: getText('Equatorial Guinea'),
  },
  {
    id: 'ER',
    name: getText('Eritrea'),
  },
  {
    id: 'EE',
    name: getText('Estonia'),
  },
  {
    id: 'ET',
    name: getText('Ethiopia'),
  },
  {
    id: 'FK',
    name: getText('Falkland Islands'),
  },
  {
    id: 'FO',
    name: getText('Faroe Islands'),
  },
  {
    id: 'FJ',
    name: getText('Fiji'),
  },
  {
    id: 'FI',
    name: getText('Finland'),
  },
  {
    id: 'FR',
    name: getText('France'),
  },
  {
    id: 'GF',
    name: getText('French Guiana'),
  },
  {
    id: 'PF',
    name: getText('French Polynesia'),
  },
  {
    id: 'TF',
    name: getText('French Southern Territories'),
  },
  {
    id: 'GA',
    name: getText('Gabon'),
  },
  {
    id: 'GM',
    name: getText('Gambia'),
  },
  {
    id: 'GE',
    name: getText('Georgia'),
  },
  {
    id: 'DE',
    name: getText('Germany'),
  },
  {
    id: 'GH',
    name: getText('Ghana'),
  },
  {
    id: 'GI',
    name: getText('Gibraltar'),
  },
  {
    id: 'GR',
    name: getText('Greece'),
  },
  {
    id: 'GL',
    name: getText('Greenland'),
  },
  {
    id: 'GD',
    name: getText('Grenada'),
  },
  {
    id: 'GP',
    name: getText('Guadeloupe'),
  },
  {
    id: 'GU',
    name: getText('Guam'),
  },
  {
    id: 'GT',
    name: getText('Guatemala'),
  },
  {
    id: 'GG',
    name: getText('Guernsey'),
  },
  {
    id: 'GN',
    name: getText('Guinea'),
  },
  {
    id: 'GW',
    name: getText('Guinea-Bissau'),
  },
  {
    id: 'GY',
    name: getText('Guyana'),
  },
  {
    id: 'HT',
    name: getText('Haiti'),
  },
  {
    id: 'HM',
    name: getText('Heard Island and McDonald Islands'),
  },
  {
    id: 'HN',
    name: getText('Honduras'),
  },
  {
    id: 'HK',
    name: getText('Hong Kong'),
  },
  {
    id: 'HU',
    name: getText('Hungary'),
  },
  {
    id: 'IS',
    name: getText('Iceland'),
  },
  {
    id: 'IN',
    name: getText('India'),
  },
  {
    id: 'ID',
    name: getText('Indonesia'),
  },
  {
    id: 'IR',
    name: getText('Iran'),
  },
  {
    id: 'IQ',
    name: getText('Iraq'),
  },
  {
    id: 'IE',
    name: getText('Ireland'),
  },
  {
    id: 'IM',
    name: getText('Isle of Man'),
  },
  {
    id: 'IL',
    name: getText('Israel'),
  },
  {
    id: 'IT',
    name: getText('Italy'),
  },
  {
    id: 'CI',
    name: getText('Ivory Coast'),
  },
  {
    id: 'JM',
    name: getText('Jamaica'),
  },
  {
    id: 'JP',
    name: getText('Japan'),
  },
  {
    id: 'JE',
    name: getText('Jersey'),
  },
  {
    id: 'JO',
    name: getText('Jordan'),
  },
  {
    id: 'KZ',
    name: getText('Kazakhstan'),
  },
  {
    id: 'KE',
    name: getText('Kenya'),
  },
  {
    id: 'KI',
    name: getText('Kiribati'),
  },
  {
    id: 'XK',
    name: getText('Kosovo'),
  },
  {
    id: 'KW',
    name: getText('Kuwait'),
  },
  {
    id: 'KG',
    name: getText('Kyrgyzstan'),
  },
  {
    id: 'LA',
    name: getText('Laos'),
  },
  {
    id: 'LV',
    name: getText('Latvia'),
  },
  {
    id: 'LB',
    name: getText('Lebanon'),
  },
  {
    id: 'LS',
    name: getText('Lesotho'),
  },
  {
    id: 'LR',
    name: getText('Liberia'),
  },
  {
    id: 'LY',
    name: getText('Libya'),
  },
  {
    id: 'LI',
    name: getText('Liechtenstein'),
  },
  {
    id: 'LT',
    name: getText('Lithuania'),
  },
  {
    id: 'LU',
    name: getText('Luxembourg'),
  },
  {
    id: 'MO',
    name: getText('Macao'),
  },
  {
    id: 'MK',
    name: getText('Macedonia'),
  },
  {
    id: 'MG',
    name: getText('Madagascar'),
  },
  {
    id: 'MW',
    name: getText('Malawi'),
  },
  {
    id: 'MY',
    name: getText('Malaysia'),
  },
  {
    id: 'MV',
    name: getText('Maldives'),
  },
  {
    id: 'ML',
    name: getText('Mali'),
  },
  {
    id: 'MT',
    name: getText('Malta'),
  },
  {
    id: 'MH',
    name: getText('Marshall Islands'),
  },
  {
    id: 'MQ',
    name: getText('Martinique'),
  },
  {
    id: 'MR',
    name: getText('Mauritania'),
  },
  {
    id: 'MU',
    name: getText('Mauritius'),
  },
  {
    id: 'YT',
    name: getText('Mayotte'),
  },
  {
    id: 'MX',
    name: getText('Mexico'),
  },
  {
    id: 'FM',
    name: getText('Micronesia'),
  },
  {
    id: 'MD',
    name: getText('Moldova'),
  },
  {
    id: 'MC',
    name: getText('Monaco'),
  },
  {
    id: 'MN',
    name: getText('Mongolia'),
  },
  {
    id: 'ME',
    name: getText('Montenegro'),
  },
  {
    id: 'MS',
    name: getText('Montserrat'),
  },
  {
    id: 'MA',
    name: getText('Morocco'),
  },
  {
    id: 'MZ',
    name: getText('Mozambique'),
  },
  {
    id: 'MM',
    name: getText('Myanmar [Burma]'),
  },
  {
    id: 'NA',
    name: getText('Namibia'),
  },
  {
    id: 'NR',
    name: getText('Nauru'),
  },
  {
    id: 'NP',
    name: getText('Nepal'),
  },
  {
    id: 'NL',
    name: getText('Netherlands'),
  },
  {
    id: 'NC',
    name: getText('New Caledonia'),
  },
  {
    id: 'NZ',
    name: getText('New Zealand'),
  },
  {
    id: 'NI',
    name: getText('Nicaragua'),
  },
  {
    id: 'NE',
    name: getText('Niger'),
  },
  {
    id: 'NG',
    name: getText('Nigeria'),
  },
  {
    id: 'NU',
    name: getText('Niue'),
  },
  {
    id: 'NF',
    name: getText('Norfolk Island'),
  },
  {
    id: 'KP',
    name: getText('North Korea'),
  },
  {
    id: 'MP',
    name: getText('Northern Mariana Islands'),
  },
  {
    id: 'NO',
    name: getText('Norway'),
  },
  {
    id: 'OM',
    name: getText('Oman'),
  },
  {
    id: 'PK',
    name: getText('Pakistan'),
  },
  {
    id: 'PW',
    name: getText('Palau'),
  },
  {
    id: 'PS',
    name: getText('Palestine'),
  },
  {
    id: 'PA',
    name: getText('Panama'),
  },
  {
    id: 'PG',
    name: getText('Papua New Guinea'),
  },
  {
    id: 'PY',
    name: getText('Paraguay'),
  },
  {
    id: 'PE',
    name: getText('Peru'),
  },
  {
    id: 'PH',
    name: getText('Philippines'),
  },
  {
    id: 'PN',
    name: getText('Pitcairn Islands'),
  },
  {
    id: 'PL',
    name: getText('Poland'),
  },
  {
    id: 'PT',
    name: getText('Portugal'),
  },
  {
    id: 'PR',
    name: getText('Puerto Rico'),
  },
  {
    id: 'QA',
    name: getText('Qatar'),
  },
  {
    id: 'CG',
    name: getText('Republic of the Congo'),
  },
  {
    id: 'RE',
    name: getText('Réunion'),
  },
  {
    id: 'RO',
    name: getText('Romania'),
  },
  {
    id: 'RU',
    name: getText('Russia'),
  },
  {
    id: 'RW',
    name: getText('Rwanda'),
  },
  {
    id: 'BL',
    name: getText('Saint Barthélemy'),
  },
  {
    id: 'SH',
    name: getText('Saint Helena'),
  },
  {
    id: 'KN',
    name: getText('Saint Kitts and Nevis'),
  },
  {
    id: 'LC',
    name: getText('Saint Lucia'),
  },
  {
    id: 'MF',
    name: getText('Saint Martin'),
  },
  {
    id: 'PM',
    name: getText('Saint Pierre and Miquelon'),
  },
  {
    id: 'VC',
    name: getText('Saint Vincent and the Grenadines'),
  },
  {
    id: 'WS',
    name: getText('Samoa'),
  },
  {
    id: 'SM',
    name: getText('San Marino'),
  },
  {
    id: 'ST',
    name: getText('São Tomé and Príncipe'),
  },
  {
    id: 'SA',
    name: getText('Saudi Arabia'),
  },
  {
    id: 'SN',
    name: getText('Senegal'),
  },
  {
    id: 'RS',
    name: getText('Serbia'),
  },
  {
    id: 'SC',
    name: getText('Seychelles'),
  },
  {
    id: 'SL',
    name: getText('Sierra Leone'),
  },
  {
    id: 'SG',
    name: getText('Singapore'),
  },
  {
    id: 'SX',
    name: getText('Sint Maarten'),
  },
  {
    id: 'SK',
    name: getText('Slovakia'),
  },
  {
    id: 'SI',
    name: getText('Slovenia'),
  },
  {
    id: 'SB',
    name: getText('Solomon Islands'),
  },
  {
    id: 'SO',
    name: getText('Somalia'),
  },
  {
    id: 'ZA',
    name: getText('South Africa'),
  },
  {
    id: 'GS',
    name: getText('South Georgia and the South Sandwich Islands'),
  },
  {
    id: 'KR',
    name: getText('South Korea'),
  },
  {
    id: 'SS',
    name: getText('South Sudan'),
  },
  {
    id: 'ES',
    name: getText('Spain'),
  },
  {
    id: 'LK',
    name: getText('Sri Lanka'),
  },
  {
    id: 'SD',
    name: getText('Sudan'),
  },
  {
    id: 'SR',
    name: getText('Suriname'),
  },
  {
    id: 'SJ',
    name: getText('Svalbard and Jan Mayen'),
  },
  {
    id: 'SZ',
    name: getText('Swaziland'),
  },
  {
    id: 'SE',
    name: getText('Sweden'),
  },
  {
    id: 'CH',
    name: getText('Switzerland'),
  },
  {
    id: 'SY',
    name: getText('Syria'),
  },
  {
    id: 'TW',
    name: getText('Taiwan'),
  },
  {
    id: 'TJ',
    name: getText('Tajikistan'),
  },
  {
    id: 'TZ',
    name: getText('Tanzania'),
  },
  {
    id: 'TH',
    name: getText('Thailand'),
  },
  {
    id: 'TG',
    name: getText('Togo'),
  },
  {
    id: 'TK',
    name: getText('Tokelau'),
  },
  {
    id: 'TO',
    name: getText('Tonga'),
  },
  {
    id: 'TT',
    name: getText('Trinidad and Tobago'),
  },
  {
    id: 'TN',
    name: getText('Tunisia'),
  },
  {
    id: 'TR',
    name: getText('Turkey'),
  },
  {
    id: 'TM',
    name: getText('Turkmenistan'),
  },
  {
    id: 'TC',
    name: getText('Turks and Caicos Islands'),
  },
  {
    id: 'TV',
    name: getText('Tuvalu'),
  },
  {
    id: 'UM',
    name: getText('U.S. Minor Outlying Islands'),
  },
  {
    id: 'VI',
    name: getText('U.S. Virgin Islands'),
  },
  {
    id: 'UG',
    name: getText('Uganda'),
  },
  {
    id: 'UA',
    name: getText('Ukraine'),
  },
  {
    id: 'AE',
    name: getText('United Arab Emirates'),
  },
  {
    id: 'GB',
    name: getText('United Kingdom'),
  },
  {
    id: 'US',
    name: getText('United States'),
  },
  {
    id: 'UY',
    name: getText('Uruguay'),
  },
  {
    id: 'UZ',
    name: getText('Uzbekistan'),
  },
  {
    id: 'VU',
    name: getText('Vanuatu'),
  },
  {
    id: 'VA',
    name: getText('Vatican City'),
  },
  {
    id: 'VE',
    name: getText('Venezuela'),
  },
  {
    id: 'VN',
    name: getText('Vietnam'),
  },
  {
    id: 'WF',
    name: getText('Wallis and Futuna'),
  },
  {
    id: 'EH',
    name: getText('Western Sahara'),
  },
  {
    id: 'YE',
    name: getText('Yemen'),
  },
  {
    id: 'ZM',
    name: getText('Zambia'),
  },
  {
    id: 'ZW',
    name: getText('Zimbabwe'),
  },
] as const satisfies Array<{ id: string; name: string }>;

export type CountryData = (typeof UNSORTED_COUNTRIES)[number];

export const COUNTRIES: CountryData[] = sort(UNSORTED_COUNTRIES, (a, b) =>
  a.name.localeCompare(b.name, CURRENT_LOCALE),
);

export const CountryNameById: Record<CountryData['id'], string> =
  COUNTRIES.reduce(
    (acc, country) => ({
      ...acc,
      [country.id]: country.name,
    }),
    {} as unknown as Record<CountryData['id'], string>,
  );
